var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _vm.message.attachments.length != 0
        ? _c(
            "div",
            [
              _c("div", { staticClass: "mt-2" }, [
                _vm._v(_vm._s(_vm.$t("ATTACHMENTS"))),
              ]),
              _vm._l(_vm.message.attachments, function (item, index) {
                return _c(
                  "span",
                  { key: index },
                  [
                    _c("Attachment", {
                      attrs: {
                        item: item,
                        message_uuid: _vm.message.message_uuid,
                        user_uuid: _vm.user_uuid,
                        functionbox_uuid: _vm.functionbox_uuid,
                        index: index,
                        filestorage: _vm.filestorage,
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
      _vm.metadata
        ? _c("MetadataFax", {
            staticClass: "clearfix mt-2",
            attrs: {
              functionbox_uuid: _vm.functionbox_uuid,
              metadata: _vm.metadata,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }