var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("BodyText", { attrs: { body: _vm.message.body } }),
      _vm.message.attachments.length != 0
        ? _c("div", [
            _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
            _c("div", { staticClass: "mt-2" }, [
              _vm._v(_vm._s(_vm.$t("ATTACHMENTS"))),
            ]),
            _c(
              "div",
              { staticClass: "mt-2" },
              _vm._l(_vm.message.attachments, function (item, index) {
                return _c(
                  "span",
                  { key: index },
                  [
                    _c("Attachment", {
                      attrs: {
                        item: item,
                        message_uuid: _vm.message.message_uuid,
                        user_uuid: _vm.user_uuid,
                        filestorage: _vm.filestorage,
                        functionbox_uuid: _vm.functionbox_uuid,
                        index: index,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }